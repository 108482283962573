/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* Color Pallet */
:root {
    --ThemeColor1: rgb(100, 162, 100);
    --ThemeColor2: rgb(73 131 73);
    --ThemeColor3: rgb(255, 213, 175);
    --ThemeColor4: rgb(255, 192, 133);
    --ThemeColor5: rgb(0, 0, 0);
    --HeaderBackground: var(--ThemeColor1);
    --NavbarText: var(--ThemeColor5);
    --NavbarTextSelected: var(--ThemeColor5);
    --FooterBackground: rgb(44, 53, 50);
    --PagesBackground: var(--ThemeColor5);
    
/* rgb(44, 53, 50);  */

    --color1:rgb(26,47,37);
    --color2:rgb(86,125,117);
    --color3:rgb(0,91,69);
    --color4:rgb(229,218,204);
    --color5:rgba(229, 218, 204, 0.804);
    --color6:rgb(49,42,35);
    --color7:rgb(0,0,0);
    --color8:rgb(255,255,255);

    --color9:#CDAEF2;
    --color10:#A0C596;
    --color11:#9D9A9B;
    --color12:#6F6F6F;
    --color13:#1A1A1A;
    --color14:rgb(185, 222, 175);
}

main{
  /* background-color: var(--color5); */
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  padding: 5% 10%;
  /* color:var(--color2) */
}
main > *{
  margin: 0px;
}

h3 {
  margin-bottom: 0px;
}


.container1 {
  position: relative;
  width:350px;
  height:200px;
  margin: 20px;;
}

.image{
  display: flex;
width: 350px;
height: 200px;
margin: 20px;
/* border-radius: 5%; */
justify-content: center;
align-items: center;
}

.overlay {
  position: absolute;
  /* display: flex; */
  display: flex;
  top: 20px;
  bottom: 0;
  left: 20px;
  right: 0;
  height: 100%;
  width: 100%;
  /* opacity: 0; */
  /* transition: .5s ease; */
  /* background-color: #008CBA; */
}

.container1:hover .image {
  filter: brightness(.35);
}

.container1:hover .overlay .text{
  opacity: 1;
}

.container1:hover .text2{
  opacity: 1;
}

.text {
  display: flex;
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 130px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
    opacity: 0;
}

.text2 {
  display: flex;
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
    opacity: 0;
}

.gitImage{
  width:30px;
  height: 30px;
  filter: invert(1);
}

svg {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.container {
  display: inline-block;
  position: absolute;
  width: 100%;
  padding-bottom: 100%;
  vertical-align: middle;
  overflow: hidden;
  top: 0;
  left: 0;
}
body {
  overflow: hidden;
}

/* pattern idea for making it feel like a tapestry */
/* body {
  background-color: #999999;
opacity: 1;
background-image:  linear-gradient(#45f75a 1.9px, transparent 1.9px), linear-gradient(to right, #45f75a 1.9000000000000001px, #999999 1.9000000000000001px);
background-size: 8px 8px;
} */

/* ~~~ HAMBURGER/SIDEBAR STYLING ~~~ */
html,  
body {
  margin: 0;
}

#App {
  font-family: 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif sans-serif;
  height: 100vh;
}

#page-wrap {
  text-align: center;
  overflow: auto;
  top: 45%;
  position: relative;
}

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  /* position: relative; */
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
  display: none;
    /* right: 0; */
    /* float: right; */
}

@media screen and (max-width: 990px) {
	.bm-burger-button {
    display: block;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #000000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  left: 8px;
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: var(--ThemeColor2);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-menu-wrap {
  /* right: 0px;
  transform: translate3d(100%, 0px, 0px); */
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.faqLists {
  list-style-type:none;
  padding: 0px;
}